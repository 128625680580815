import React from 'react';
import './FAQ.scss';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import marked from 'marked';

function FAQ({ title, faqs, page }) {
  const sectionClass = `faq${(page || '') && ' faq--' + page}`;

  const [ expanded, setExpanded ] = React.useState(false);

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };

  const renderList = (list) => list.map((item, index) => (
    <div className="faq__item" key={index}>
      <Accordion
      expanded={expanded === index}
      onChange={handleChange(index)}
      >
        <div className="faq__item-summary">
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <p className="faq__item-title">
              {item.question}
            </p>
          </AccordionSummary>
        </div>
        <div className="faq__item-details">
          <AccordionDetails>
            <div className="faq__item-description"
                 dangerouslySetInnerHTML={{__html: marked(item.answer)}}
            />
          </AccordionDetails>
        </div>
      </Accordion>
    </div>
  ));

  return (
    <section className={sectionClass}>
      <div className="faq__wrapper">
        <div className="container container--narrow faq__container">
          <div className="faq__inner">
            <p className="faq__title section-title">
              {title}
            </p>
            {renderList(faqs)}
          </div>
        </div>
      </div>
    </section>
  );
}

export default React.memo(FAQ);
